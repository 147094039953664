import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeLayout from '../layouts/HomeLayout.vue'
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeLayout,
        children: [
            {
                path: '/',
                name: 'main',
                component: () => import('../views/MainView.vue'),
                meta: {
                    auth: true,
                }

            },
            {
                path: '/sign-in',
                name: 'sign-in',
                component: () => import('../views/SignInView.vue'),
            },
            {
                path: 'tasks/:nominationId',
                name: 'tasks',
                component: () => import('../views/TasksView.vue'),
                meta: {
                    auth: true,
                }
            }
        ]
    },
    {path: "/404", component: () => import('../views/PageNotFound.vue')}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
        if (to === from) {
            return;
        }
        if (!to.matched.length) {
            next('/404');
            return;
        } else {
            const guardRoute = to.matched.some(record => record.meta.auth);
            const game = store.getters.client;
            const auth = game && game.authorized;
            if (guardRoute && !auth) {
                next({path: "/sign-in"})
            } else if (!guardRoute && auth) {
                next({path: "/"})
             } else {
                next()
            }
        }
    }
)

export default router
