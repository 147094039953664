<template>
  <div class="mt-3 logo">
    <router-link to="/">
      <img alt="logo" :src="img"/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "logo",
  computed: {
    img() {
      if(['/sign-in','/game/rules','/rating'].includes(this.$route.path)){
        return require('../assets/logo_color.svg');
      }
      return require('../assets/logo.svg');
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  text-align: center;
  /*margin-top: 2rem;*/
  img {
    height: 20px;
  }

  @media (min-width: 992px) {
    text-align: left;
    height: 40px;
  }
}
</style>
